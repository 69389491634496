/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unused-vars-experimental */
import React, { useEffect, useState } from "react";
import { CompetitionData } from "./types";
import { useCompetitionData } from "./useCompetitionData";
import { Leaderboard } from "./Leaderboard";
import { Predictions } from "./Predictions";
import { Fixtures } from "./Fixtures";
import { useAuthContext } from "../context/auth-context";

const Dashboard = () => {
    const { retrieveCompetitionData } = useCompetitionData();
    const [competitionData, updateCompetitionData] = useState<CompetitionData>();
    const [newName, updateNewName] = useState<string>("");
    const { submitName } = useCompetitionData();

    useEffect(() => {
        const fetchData = async () => {
            const data: CompetitionData = await retrieveCompetitionData(false);
            updateCompetitionData(data);
        };
        fetchData();

        const interval = setInterval(() => {
            fetchData();
        }, 30000);

        return () => clearInterval(interval);
    }, []);

    const { email } = useAuthContext();
    const { players, fixtures } = competitionData ?? { players: [], fixtures: [] };
    const { predictions, name } = players.find(player => player.email === email) ?? { predictions: [] };

    const handleFormSubmit = () => {
        submitName(newName);
        // eslint-disable-next-line no-restricted-globals
        setTimeout(() => location.reload(), 500);
    };

    return (
        <main className="container-fluid">
            {name ? (
                <>
                    <div className="row">
                        <Leaderboard players={players} />
                        <Predictions predictions={predictions} fixtures={fixtures} />
                    </div>
                    <div className="row">
                        <Fixtures fixtures={fixtures} players={players} email={email} />
                    </div>
                </>
            ) : (
                <div className="row">
                    <div className="col-md-6 container text-center p-md-5 pt-5 pb-0 mb-5">
                        <h3>Welcome to the new and improved Super Saturday app!</h3>
                        <p>Before we go any further...</p>
                        <button
                            type="button"
                            className="btn btn-success btn-lg shadow-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#name-form"
                        >
                            Introduce yourself!
                        </button>
                        <div
                            className="modal fade"
                            id="name-form"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Hello there!
                                        </h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                    </div>
                                    <div className="modal-body">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">
                                                Tell us your name:
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                placeholder="Chris Paterson"
                                                value={newName}
                                                onChange={({ target: { value } }) => updateNewName(value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            data-bs-dismiss="modal"
                                            onClick={handleFormSubmit}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </main>
    );
};

export { Dashboard };
